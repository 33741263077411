import PropTypes from "prop-types";
import React, { Fragment, useState, useRef, useEffect } from "react";
import HeaderTwo from "../wrappers/header/HeaderTwo";
import FooterOne from "../wrappers/footer/FooterOne";
import ScrollToTop from "../components/scroll-to-top";
import { getUserPrefersLang } from "../shared/helpersMethod";
import ArrowLeft from "../assets/svgs/ArrowLeft";
import useGetHomeCategory from "../hooks/useGetCategories";
import { useAppDispatch, useAppSelector } from "../hooks/useReduxMethods";
import WhatsappButton from "../components/whatsappContact/WhatsappButton";
import { setBreadCrumCategoryName } from "../store/slices/breadCrums";
const LayoutTwo = ({ children, footerBgClass }) => {
  const scrollRefCategories = useRef(null);
  const userLang = getUserPrefersLang();
  const { getMobileMenu, mobileMenu } = useGetHomeCategory();
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const [isScrollableRight, setIsScrollableRight] = useState(false);
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const { homePageCategories } = useAppSelector(
    (state) => state.homePageCategories
  );
  const dispatch = useAppDispatch();
  const CustomRightArrow = ({ onClick }) => {
    // onMove means if dragging or swiping in progress.
    // <button aria-label="Go to previous slide" class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left " type="button" onClick={() => onClick()}></button>
    return (
      <button
        disabled={!isScrollableRight}
        className="mobile-nav-arrow right"
        style={{
          right: 0,
          boxShadow: "4px 2px 7px 8px rgba(159,197,234,.49)",
          // left: userLang == 'ar' && 0,
          // borderLeft: "1px solid black",
        }}
        onClick={onClick}
      >
        <ArrowLeft />
      </button>
    );
  };
  const CustomLeftArrow = ({ onClick }) => {
    // onMove means if dragging or swiping in progress.
    // <button aria-label="Go to previous slide" class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left " type="button" onClick={() => onClick()}></button>
    return (
      <button
        disabled={!isScrollableLeft}
        className="mobile-nav-arrow left"
        style={{
          // right: userLang == 'ar' && 0,
          left: 0,
          boxShadow: "4px 2px 7px 1px rgba(159,197,234,.49)",
          //  left: 0,
          // borderRight: "1px solid black"
        }}
        onClick={onClick}
      >
        <ArrowLeft dimension="right" />
      </button>
    );
  };
  const updateCategoryBreadCrums = (item: any) => {
    dispatch(
      setBreadCrumCategoryName({
        name: item.name,
        id: item.unique_id!,
        slug: item.slug,
      })
    );
  };
  useEffect(() => {
    const container = scrollRefCategories.current;

    const handleScroll = () => {
      if (userLang == "en" && null !== container) {
        setIsScrollableLeft(container.scrollLeft > 0);
        setIsScrollableRight(
          container.scrollLeft + container.clientWidth < container.scrollWidth
        );
        return;
      }
      if (userLang == "ar" && null !== container) {
        setIsScrollableRight(container.scrollLeft < 0);
        setIsScrollableLeft(
          Math.abs(container.scrollLeft) <
            container.scrollWidth - container.clientWidth
        );
        return;
      }
    };
    if (null !== container) {
      handleScroll(); // Initial check
      // Listen to scroll events
      container.addEventListener("scroll", handleScroll);
    }

    // Clean up event listener
    return () => {
      if (null !== container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [userLang]);
  useEffect(() => {
    console.log("width", window.screen.width);
    if (window.screen.width < 576) {
      console.log("called");
      getMobileMenu("mobile", "Y");
    }
  }, [window.screen.width]);
  useEffect(() => {
    console.log(mobileMenu);
  }, [mobileMenu]);
  return (
    <Fragment>
      <HeaderTwo homePageCategories={homePageCategories} />

      {bussinessData?.homepage_configurations.display_sections
        .display_mobile_menu_categories === "Yes" && (
        <div className="mobile-department d-lg-none bg-glaucous">
          <ul ref={scrollRefCategories}>
            <CustomLeftArrow
              onClick={() => {
                if (scrollRefCategories.current) {
                  scrollRefCategories.current.scrollLeft -= 100; // Adjust scroll amount as needed
                }
              }}
            />
            <CustomRightArrow
              onClick={() => {
                if (scrollRefCategories.current) {
                  console.log(scrollRefCategories.current.scrollLeft);
                  scrollRefCategories.current.scrollLeft += 100; // Adjust scroll amount as needed
                }
              }}
            />

            {mobileMenu &&
              mobileMenu.map((item: any, index: number) => {
                return (
                  <li key={index}>
                    <div className="mobile-nav-menu-items">
                      <a
                        onClick={() => updateCategoryBreadCrums(item)}
                        href={`/collections/${item.slug}/${item.unique_id}`}
                        className="waiting lazyloaded"
                      >
                        <img
                          alt={item.name}
                          className="img-categories-mobile-view"
                          src={item.category_template_image}
                        />
                      </a>
                      <span className="text-mobile-menu bold">{item.name}</span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {children}
      <FooterOne
        backgroundColorClass={footerBgClass ? footerBgClass : "bg-gray"}
        spaceTopClass="pt-50"
        spaceBottomClass="pb-30"
      />
      <ScrollToTop />
      {bussinessData && [167].includes(bussinessData.businessID) && (
        <WhatsappButton />
      )}
    </Fragment>
  );
};

LayoutTwo.propTypes = {
  children: PropTypes.node,
  footerBgClass: PropTypes.string,
};

export default LayoutTwo;
