import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const { t } = useTranslation();
  return (
    // height: 26px;
    // width: 103px;
    // object-fit: contain;
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
          {/* <div className="footer-bussiness-logo" style={{backgroundImage:`url(${footerLogo})`}}/> */}
          <img
            className="logo-header-img"
            alt={"footer-logo-" + window.businessByDomain}
            src={process.env.PUBLIC_URL + footerLogo}
            width={"100%"}
          />
        </Link>
      </div>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
